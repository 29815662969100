import React, {useContext, useEffect, useState} from 'react';
import {Link, NavLink, useHistory} from 'react-router-dom';
import {
	DropdownArrowIcon,
	UserIcon,
	QuestionIcon,
	FavoriteIcon
} from '../../../icons';
import {MenuTitle, UserSettingsInfo} from './Menu.styles';
import DropdownMenu from '../../ui/DropdownMenu/DropdownMenu';
import {Search} from '../../index';
import {AuthUser, logout} from '../../../actions/restactions';
import {BillingIcon} from '../../../icons/svg/BillingIcon';
import AuthContext from '../../../contexts/auth/AuthContext';

function Menu(){

	const history = useHistory();

	if (!document.documentElement.classList.contains('dark')) {
		document.documentElement.classList.add('dark');
	}

	const authContext = useContext(AuthContext);
	const {logoutUser} = authContext;

	const [menu] = useState([
		{
			to:'/mood',
			name:'Moods',
			icon: <BillingIcon/>
		},
		{
			to:'/avatar',
			name:'Avatars',
			icon: <QuestionIcon/>
		}
	]);

	const [menu2]= useState([{
		to:'/users/all',
		name:'Users',
		icon: <UserIcon/>
	}
	]);

	const [schoolCodesMenu]= useState([{
		to:'/school-codes',
		name:'School Codes',
		icon: <BillingIcon/>
	}
	]);

	const [menu3] = useState([
		{
			to:'/sparks-libraries',
			name:'Spark Libraries',
			icon: <BillingIcon/>
		}
	]);

	const curriculumsSubMenu = {
		title: {
			title: 'Curriculums',
			iconTitle: <FavoriteIcon/>
		},
		items:[
			{
				to:'/lessons',
				name:'Lessons',
				icon: <></>
			},
			{
				to:'/courses',
				name:'Courses',
				icon: <></>
			},
		]
	};

	const tagsSubMenu = {
		title: {
			title: 'SEL Tags',
			iconTitle: <FavoriteIcon/>
		},
		items: [
			{
				to: '/lifeSkills',
				name: 'Life Skills',
				icon: <></>
			},
			{
				to: '/themes',
				name: 'Themes',
				icon: <></>
			},
			{
				to: '/skillSet',
				name: 'Skill Set',
				icon: <></>
			}
		]
	};

	const userDataSubMenu = {
		title: {
			title: 'User Data',
			iconTitle: <FavoriteIcon/>
		},
		items: [
			{
				to:'/checkin',
				name:'Check In',
				icon: <></>
			},
			{
				to:'/journals',
				name:'Journals',
				icon: <></>
			}
		]
	};

	const organizationsSubMenu = {
		title: {
			title: 'Organizations',
			iconTitle: <FavoriteIcon/>
		},
		items: [
			{
				to: '/districts',
				name: 'Districts',
				icon: <></>
			},
			{
				to: '/schools',
				name: 'Schools',
				icon: <></>
			}
		]
	};

	const gamificationSubMenu = {
		title: {
			title: 'Gamification',
			iconTitle: <BillingIcon/>
		},
		items: [
			{
				to: '/badges',
				name: 'Badges',
				icon: <></>
			},
			{
				to: '/pin',
				name: 'Pins',
				icon: <></>
			},
			{
				to: '/stickers',
				name: 'Stickers',
				icon: <></>
			},
			{
				to:'/collectibles',
				name:'Collectibles',
				icon: <></>
			}
		]
	};

	const [authUser, setAuthUser] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		AuthUser().then(data => {
			setAuthUser(data);
			setLoading(false);
		});
	}, []);

	return (
		<div className='font-mono p h-screen bg-background-light dark:bg-background-dark left-main-menu-border'>
			<MenuTitle className={'font-mono p-2 text-text-light dark:text-text-dark'}>BluePrint Admin</MenuTitle>
			<Search onSearch={() => {
			}} className={''} style={{marginBottom: '30px'}}/>
			{menu2.map(({to, name, icon}) => (
				<NavLink
					to={to}
					key={to}
					className='flex items-center p-2 my-2 rounded-lg text-text-light dark:text-text-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
					activeClassName='font-bold active-nav-item-font-color'
				>
					{icon}
					<span className='ml-2'>{name}</span>
					{/*<DropdownArrowIcon className='ml-auto'/>*/}
				</NavLink>
			))}
			<DropdownMenu hide={true} items={curriculumsSubMenu.items} title={curriculumsSubMenu.title}/>
			{menu3.map(({to, name, icon}) => (
				<NavLink
					to={to}
					key={to}
					className='flex items-center p-2 my-2 rounded-lg text-text-light dark:text-text-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
					activeClassName='font-bold active-nav-item-font-color'
				>
					{icon}
					<span className='ml-2'>{name}</span>
					{/*<DropdownArrowIcon className='ml-auto'/>*/}
				</NavLink>
			))}
			<DropdownMenu hide={true} items={tagsSubMenu.items} title={tagsSubMenu.title}/>
			<DropdownMenu hide={true} items={gamificationSubMenu.items} title={gamificationSubMenu.title}/>
			{menu.map(({to, name, icon}) => (
				<NavLink
					to={to}
					key={to}
					className='flex items-center p-2 my-2 rounded-lg text-text-light dark:text-text-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
					activeClassName='font-bold active-nav-item-font-color'
				>
					{icon}
					<span className='ml-2'>{name}</span>
					{/*<DropdownArrowIcon className='ml-auto'/>*/}
				</NavLink>
			))}
			<DropdownMenu hide={true} items={userDataSubMenu.items} title={userDataSubMenu.title}/>
			<div className={'left-main-menu-separator mt-2 mb-2'}></div>
			<DropdownMenu hide={true} items={organizationsSubMenu.items} title={organizationsSubMenu.title}/>
			{schoolCodesMenu.map(({to, name, icon}) => (
				<NavLink
					to={to}
					key={to}
					className='flex items-center p-2 my-2 rounded-lg text-text-light dark:text-text-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
					activeClassName='font-bold active-nav-item-font-color'
				>
					{icon}
					<span className='ml-2'>{name}</span>
				</NavLink>
			))}
			<div className={'left-main-menu-separator mt-2 mb-2'}></div>
			{
				!loading && authUser ? (
					<UserSettingsInfo
						onClick={()=>history.push('/admin-profile')}
						className={'flex justify-between items-center mb-2 p-1 gap-1 cursor-pointer rounded-lg hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
						<div className={'flex justify-stretch items-center p-1'}>
							<div>
								{
									authUser.profilePicture?.url === undefined || authUser.profilePicture?.url === null || authUser.profilePicture?.url === 'test' || authUser.profilePicture?.url === 'artwork' ? (
										<div>
											<UserIcon width={'1rem'} height={'1rem'}/>
										</div>
									) : (
										<img
											src={authUser.profilePicture.url || 'https://cdna.artstation.com/p/assets/images/images/029/284/552/large/alexandre-augusto-agumon.jpg?1597090850'}
											alt={authUser.username}
										/>
									)
								}
							</div>
							<div>
								<div className={'grid-row038'}>
									<span className={'font-mono'}>{authUser.firstName} {authUser.lastName}</span>
									<span className={'font-mono'}>{authUser.email}</span>
								</div>
							</div>
						</div>
						<div>
							<DropdownArrowIcon className='ml-auto align-bottom'/>
						</div>
					</UserSettingsInfo>
				) : (
					<UserSettingsInfo
						className={'flex justify-between items-center mb-2 p-1 gap-1 cursor-pointer rounded-lg hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
						<div className={'flex justify-stretch items-center p-1'}>
							<div>
								<div>
									<UserIcon width={'1rem'} height={'1rem'}/>
								</div>
							</div>
							<div>
								<div className={'grid-row038'}>
									<span className={'font-mono'}>Please Log In</span>
								</div>
							</div>
						</div>
						<div>
							<DropdownArrowIcon className='ml-auto align-bottom'/>
						</div>
					</UserSettingsInfo>
				)
			}
			{
				localStorage.getItem('accessToken') ?
					<Link to='/login' onClick={() => {
						logoutUser();
						logout();
					}}
						  className='f-small pver-half phor br-quarter inline-flex border border-red-500 text-red-500 hover:grey900'>Logout</Link>
					:
					<Link to='/login'
						  className='f-small pver-half phor br-quarter inline-flex black t-white hover:grey900'>Login</Link>
			}
		</div>
	);
}

export default Menu;